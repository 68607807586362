import { GameScenario, GAME_STEP_TYPE } from "./types";

export const SCENARIO: GameScenario = {
    startStep: '1.0',
    steps: [
        {
            id: '1.0',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-map',
            nextStep:'1.1',
        },
        {
            id: '1.1',
            type: GAME_STEP_TYPE.GEO_STEP,
            position: {
                lat: 53.79516, 
                lng: 17.19552
            },
            name: ['Punkt geo 1'],
            nextStep: '2.1'
        },
        {
            id: '2.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-1', //ułóż w kolejności grafiki
            nextStep: '3.1'
        },
        {
            id: '3.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-2', //układanka 4x4
            nextStep: '4.1'
        },
        {
            id: '4.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-3', //memory
            nextStep: '5.1'
        },
        {
            id: '5.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-4', //quiz
            nextStep: '6.1'
        },
        {
            id: '6.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-5',//quiz
            nextStep: '7.1'
        },
        {
            id: '7.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-6',//quiz
            nextStep: '8.1'
        },
        {
            id: '8.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-7',//quiz
            nextStep: '9.1'
        },
        {
            id: '9.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-8',//quiz
            nextStep: '10.1'
        },
        {
            id: '10.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-9',//bravo
            nextStep: '10.2'
        },
        {
            id: '10.2',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-10',//bravo
            nextStep: '10.3'
        },
        {
            id: '10.3',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-11',//bravo
            nextStep: '10.4'
        },{
            id: '10.4',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-12',//bravo
            nextStep: '10.5'
        },{
            id: '10.5',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-13',//bravo
            nextStep: '10.6'
        },{
            id: '10.6',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-14',//bravo
            nextStep: '10.7'
        },{
            id: '10.7',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-15',//bravo
            nextStep: '10.8'
        },{
            id: '10.8',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-16',//bravo result
            nextStep: '11'
        },{
            id: '11',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-17',//bravo result
            nextStep: '11.1'
        },
    ]
}