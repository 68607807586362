import rotate from "../../assets/willa/obroctelefon.png"
export default function ErrorMessage(){
    return(
        <div className="ErrorMessege">
            <img src={rotate} alt="" />
            <p>
                Proszę obrócić urządzenie, aby kontynuować
            </p>
        </div>
    )
}